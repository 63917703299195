import useDirections from "../../../hooks/functionality/useDirections";
import useMapFitBounds from "../../../hooks/functionality/useMapFitBounds";
import { MapPointOLD } from "../../../shared/types/internal";
import PointsMarker from "../PointsMarker";

type Props = {
    points: MapPointOLD[];
    showDirections?: boolean;
};

function PointsMarkers(props: Props) {
    useMapFitBounds({
        path: props.points.map((point) => point.location),
    });
    useDirections({
        hide: !props.showDirections,
        path: props.points.map((point) => point.location),
    });

    return (
        <>
            {props.points.map((point, i) => (
                <PointsMarker key={i} point={point} />
            ))}
        </>
    );
}

export default PointsMarkers;
