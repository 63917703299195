import { useSelector } from "react-redux";
import { ReduxState } from "../../shared/types/redux";
import { useMemo } from "react";
import { DEFAULT_CENTER } from "../../shared/values/enums";

function useDefaultMapCenter() {
    const { user } = useSelector((state: ReduxState) => state.auth);

    const defaultCenter = useMemo(() => {
        if (user?.location_entity?.lat && user?.location_entity?.lng) {
            return {
                lat: user.location_entity.lat,
                lng: user.location_entity.lng,
            };
        }
        return DEFAULT_CENTER;
    }, [user?.location_entity?.lat, user?.location_entity?.lng]);

    return defaultCenter;
}

export default useDefaultMapCenter;
