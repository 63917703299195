import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AdvancedMarker } from "@vis.gl/react-google-maps";
import MapInfoWindow from "../../../components/UI/MapInfoWindow";
import "./style.scss";

type Props = {
    location: google.maps.LatLngLiteral;

    onClick?: (event: google.maps.MapMouseEvent) => void;
    zIndex?: number;
    label?: string;
    icon?: IconDefinition;
    isInfoWindowOpen?: boolean;
    infoWindowData?: {
        title: string;
        description?: string;
    };
};

function MapMarker(props: Props) {
    return (
        <AdvancedMarker
            position={props.location}
            onClick={props.onClick}
            zIndex={props.zIndex}
        >
            <div className="map-marker">
                {props.icon && (
                    <FontAwesomeIcon
                        icon={props.icon}
                        color="var(--color-pure-white)"
                    />
                )}
                {props.label && <span className="text-xs">{props.label}</span>}
            </div>
            {props.infoWindowData && props.isInfoWindowOpen && (
                <MapInfoWindow
                    title={props.infoWindowData.title}
                    description={props.infoWindowData.description}
                />
            )}
        </AdvancedMarker>
    );
}

export default MapMarker;
