import { useQuery } from "@tanstack/react-query";
import { getGoogleAutoComplete } from "../../api/google";

function useAddressAutoComplete({
    searchText,
    regionCode,
}: {
    searchText: string;
    regionCode?: string;
}) {
    const {
        data: predictions,
        status,
        refetch,
        isFetching,
    } = useQuery({
        queryKey: ["address-auto-complete", searchText, regionCode] as const,
        queryFn: async ({ queryKey: [, searchText, regionCode] }) => {
            if (searchText.length < 3) {
                return [];
            }

            const data = await getGoogleAutoComplete(searchText, regionCode);

            if (!data?.length) {
                return [
                    {
                        place_id: "no-results",
                        description: searchText,
                        matched_substrings: [],
                        terms: [],
                    },
                ] as any as google.maps.places.AutocompletePrediction[];
            }

            return data;
        },
        placeholderData: (prev) => prev,
    });

    switch (status) {
        case "success":
            return {
                predictions,
                status,
                refetch,
                isFetching,
            };
        case "pending":
            return {
                predictions,
                status,
                refetch,
                isFetching,
            };
        case "error":
            return {
                predictions,
                status,
                refetch,
                isFetching,
            };
    }
}

export default useAddressAutoComplete;
