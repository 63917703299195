import Map from "../Map";
import { useSelector } from "react-redux";
import { MapPointOLD } from "../../shared/types/internal";
import { ReduxState } from "../../shared/types/redux";
import { DEFAULT_CENTER } from "../../shared/values/enums";
import PointsMarkers from "../markers/PointsMarkers";
import "./style.scss";

type Props = {
    points: MapPointOLD[];
    extraPoints?: MapPointOLD[];
    mapId: string;
    showDirections?: boolean;
};

function PointsMapOLD(props: Props) {
    const { user } = useSelector((state: ReduxState) => state.auth);

    return (
        <div className="points-map-old">
            <Map
                mapId={props.mapId}
                defaultCenter={
                    user?.location_entity?.lat && user?.location_entity?.lng
                        ? {
                              lat: user.location_entity.lat,
                              lng: user.location_entity.lng,
                          }
                        : DEFAULT_CENTER
                }
                defaultZoom={12}
                showTrafficToggle
            >
                <PointsMarkers
                    points={props.points}
                    showDirections={props.showDirections}
                />
                {props.extraPoints && props.extraPoints.length > 0 && (
                    <PointsMarkers points={props.extraPoints} />
                )}
            </Map>
        </div>
    );
}

export default PointsMapOLD;
