import { useMemo } from "react";
import useDefaultMapCenter from "../../hooks/functionality/useDefaultMapCenter";
import useDirections from "../../hooks/functionality/useDirections";
import useMapFitBounds from "../../hooks/functionality/useMapFitBounds";
import { MapPoint } from "../../shared/types/internal";
import Map from "../Map";
import MapMarker from "../markers/MapMarker";
import "./style.scss";

type Props = {
    mapId: string;
    points: MapPoint[];
    nonDirectionPoints?: MapPoint[];
    showDirections: boolean;
    directionsColor?: string;
};

function PointsMap(props: Props) {
    const defaultCenter = useDefaultMapCenter();

    useDirections({
        hide: !props.showDirections,
        path: props.points.map((p) => p.location),
        directionsColor: props.directionsColor,
    });

    const boundsPoints = useMemo(() => {
        return [
            props.points,
            props.nonDirectionPoints ? props.nonDirectionPoints : [],
        ].flat();
    }, [props.nonDirectionPoints, props.points]);

    useMapFitBounds({
        path: boundsPoints.map((p) => p.location),
        boundsPadding: 100,
    });

    return (
        <div className="points-map">
            <Map
                mapId={props.mapId}
                defaultCenter={defaultCenter}
                defaultZoom={12}
                showTrafficToggle
            >
                {props.points.map((point) => (
                    <MapMarker
                        key={point.id}
                        location={point.location}
                        label={point.label}
                        icon={point.icon}
                        infoWindowData={
                            point.name
                                ? {
                                      title: point.name,
                                      description: point.description,
                                  }
                                : undefined
                        }
                    />
                ))}
                {props.nonDirectionPoints &&
                    props.nonDirectionPoints.map((point) => (
                        <MapMarker
                            key={point.id}
                            location={point.location}
                            label={point.label}
                            icon={point.icon}
                            infoWindowData={
                                point.name
                                    ? {
                                          title: point.name,
                                          description: point.description,
                                      }
                                    : undefined
                            }
                        />
                    ))}
            </Map>
        </div>
    );
}

export default PointsMap;
